var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.rimData.rsltImprId,
                                expression: "rimData.rsltImprId",
                              },
                            ],
                            attrs: {
                              label: "삭제",
                              editable: _vm.editable,
                              icon: "delete_forever",
                            },
                            on: { btnClicked: _vm.removeData },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable,
                                expression: "editable",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.rimData,
                              mappingType: _vm.mappingType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "제목+그룹사명",
                            name: "rsltImprTitle",
                          },
                          model: {
                            value: _vm.rimData.rsltImprTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.rimData, "rsltImprTitle", $$v)
                            },
                            expression: "rimData.rsltImprTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "일자",
                            name: "rsltImprDate",
                          },
                          model: {
                            value: _vm.rimData.rsltImprDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.rimData, "rsltImprDate", $$v)
                            },
                            expression: "rimData.rsltImprDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 4,
                            label: "내용",
                            name: "rsltImprContents",
                          },
                          model: {
                            value: _vm.rimData.rsltImprContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.rimData, "rsltImprContents", $$v)
                            },
                            expression: "rimData.rsltImprContents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-sm",
                  },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }