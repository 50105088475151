<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-show="rimData.rsltImprId" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              <c-btn
                v-show="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="rimData"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <c-text
                required
                :editable="editable"
                label="제목+그룹사명"
                name="rsltImprTitle"
                v-model="rimData.rsltImprTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                :editable="editable"
                label="일자"
                name="rsltImprDate"
                v-model="rimData.rsltImprDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :rows="4"
                label="내용"
                name="rsltImprContents"
                v-model="rimData.rsltImprContents">
              </c-textarea>
            </div>
          </template>
        </c-card>
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-sm">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable">
            </c-upload>
          </div>
        </template>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'result-improve-manage-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        rsltImprId: '',
      }),
    },
  },
  data() {
    return {
      attachable: false,
      saveUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      rimData: {
        rsltImprId: '',
        plantCd: '',
        rsltImprDate: '',
        rsltImprContents: '',
        rsltImprTitle: '',

        regUserId: '',
        chgUserId: '',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RESULT_IMPROVE_MANAGE',
        taskKey: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      updateMode:false,
      realKey: '',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.gci.rim.get.url;
      this.saveUrl = transactionConfig.gci.rim.insert.url;
      this.insertUrl = transactionConfig.gci.rim.insert.url;
      this.updateUrl = transactionConfig.gci.rim.update.url;
      this.deleteUrl = transactionConfig.gci.rim.delete.url;
      // code setting
      this.getDetail();
      // list setting
    },
    getDetail() {
      if (this.popupParam.rsltImprId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.rsltImprId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.rimData.imprImmData =[]
          this.rimData.imprData =[]
          this.rimData = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.rsltImprId)
        },);
      } else {
        this.rimData.rsltImprDate = this.$comm.getToday();
      }
    },
    saveData() {
      if (this.rimData.rsltImprId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.rimData.regUserId = this.$store.getters.user.userId;
              this.rimData.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.rimData.rsltImprId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.popupParam.rsltImprId = result.data
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.getDetail();
    },
  }
};
</script>
